@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;700&display=swap");

html,
body {
  font-family: "Source Code Pro", monospace;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1a1a1d;
}

.joinInnerContainer {
  width: 18%;
}

.joinInput {
  border-radius: 10px;
  font-size: 18px;
  padding: 1rem 2rem;
  font-family: "Source Code Pro", monospace;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.join-button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979ff;
  font-size: 18px;
  font-weight: 500;
  padding: 1rem 2rem;
  font-family: "Source Code Pro", monospace;
  border-radius: 10px;
  display: inline-block;
  border: none;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }
}

button:focus {
  outline: 0;
}
