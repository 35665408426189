@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;700&display=swap");

*::after,
*::before {
  box-sizing: border-box;
}

body {
  background-color: #0d1013;
  margin: 0;
}

.container {
  margin: 2rem 10rem 5rem 10rem;
  text-align: right;
}

.button-container {
  display: flex;
  align-self: flex-start;
}

.button {
  margin: 1rem 1rem 0 0;
  background-color: #026dd9;
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Source Code Pro", monospace;
}

.new-file {
  display: inline-block;
  margin: 0 1rem 1rem 0;
}

.code-container {
  display: flex;
}

.compiler-container .ql-editor,
.editor-container .ql-editor {
  width: 50vw;
  height: 40rem;
  padding: 2rem;
  margin-right: 2rem;
  background-color: #1a2026;
  font-size: 16px;
  font-family: "Source Code Pro", monospace;
  color: whitesmoke;
  border-radius: 10px;
}

.compiler-container .ql-editor {
  width: 30vw;
}

.compiler-container .ql-container.ql-snow,
.editor-container .ql-container.ql-snow {
  border: none;
  display: flex;
  justify-content: center;
}

.editor-container .ql-toolbar.ql-snow {
  display: none;
}

.compiler-container .ql-toolbar.ql-snow {
  display: none;
}
